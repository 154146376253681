<template>
  <organisms-accordion-push v-bind="sliceData" />
</template>

<script setup>
import { computed } from 'vue';
import { getSliceComponentProps } from '@prismicio/vue';
import getJSONLD from '@/utils/structured-data';
import accordionPushAdapter from '@/slices/AccordionPush/adapter';
import { useHead } from '#imports';

const props = defineProps(
  getSliceComponentProps(['slice', 'index', 'slices', 'context']),
);

const sliceData = computed(() => accordionPushAdapter(props.slice));

useHead({
  script: [getJSONLD('faq', sliceData.value)],
});
</script>
